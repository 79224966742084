$mainBg: #FFCC00;
$mainBgGradient: linear-gradient(90deg, #FFCC00 0.06%, #FFCC00 30.02%, #FFE57F 78.97%, #FFF0B2 99.94%);
$dark : #0B0D17;
$dark2 : #262626;
$dark3 : #62636D;
$dark4 : #8C8C8C;
$dark5 : #D2D2D2;
$light: #F1F6FA;
$light2: #F6F6F9;;
$light3: #E3F8FF;
$light4: #EDFAFF;
$light5: #F6FDFF;
$white: #FFFFFF;

$error : #FF3B3B;
$warning : #FFCC00;
$info : #0063F7;
$success : #06C270;

$green: #25D366;
$green1: #D3F6E0;
$green--5: #F4FDF7;

$red: #D40511;
$red1: #F9D1D2;
$red--5: #FDF3F3;



$black7: #000000;
$black6: #333333;
$black5: #666666;
$black4: #808080;
$black3: #B3B3B3;
$black2: #E6E6E6;
$black1: #F2F2F2;
$black--5: #F9F9F9;

// FONT SIZE
$rb-56: 56px; $rb-64 :64px;
$rb-24: 24px;
$rb-36: 36px;
$rb-14: 14px;
$rb-13: 13px;
$rb-18: 18px;
$rb-15: 15px;
$rb-16: 16px;
$rb-20: 20px;
$rb-32: 32px; $rb-42: 42px;




//FONTS
$r_regular : "Roboto-Regular";
$r_medium : "Roboto-Medium";
$r_bold : "Roboto-Bold";
$r_light : "Roboto-Light";
//RESPONSIVE
$mobile: 992px;
