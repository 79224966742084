@font-face {
    font-family: $r_regular;
    src: url(../../fonts/Roboto-Regular.woff);
}

@font-face {
    font-family: $r_medium;
    src: url(../../fonts/Roboto-Medium.woff);
}

@font-face {
  font-family: $r_bold;
  src: url(../../fonts/Roboto-Bold.woff);
}

@font-face {
  font-family: $r_light;
  src: url(../../fonts/Roboto-Light.woff);
}


