/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import "assets/sass/base/global";
@import "assets/sass/base/variables";
@import "assets/sass/base/fonts";
@import "assets/sass/single/flex";
@import "assets/sass/single/display";


*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body{
  font-family:$r_regular;
}


//////////HOME HEADER FOOTER STARTS/////////////
a {
  text-decoration: none;
}
button{
  cursor: pointer;
}
.main_header{
  width: 100%;
  height: 96px;
  background: $mainBgGradient;
}
.main_header_content{
  height: 100%;
}
.menu_nav_items, .dropdown {
  //background: darkorange;
  list-style: none;
  margin: 0;
  padding-left: 0;
  transition: all 0.5s ease;
}
.dropdown{
  background: $white;
  width: 180px;
  box-shadow: 0px 19px 26px rgba(0, 0, 0, 0.16);
  padding: 16px 0;
  z-index: 1000;
}

.menu_nav_item {
  color: $dark;
  //background: darkorange;
  display: block;
  float: left;
  padding: 10px 22px;
  position: relative;
  text-decoration: none;
  transition: all 0.5s ease;
  font-family: $r_regular;
  font-size: $rb-14;
}

.menu_nav_item a {
  color: $dark;
}
.dropdown_item{
  padding: 16px;
  font-size: $rb-16;
  width: 100%;
}
.dropdown_item:hover{
  background: $red;
  color: $white;
}
.menu_nav_item:hover {
  //background: red;
  color: white;
  cursor: pointer;
}

.menu_nav_item:focus-within a {
  outline: none;
}

.menu_nav_items .menu_nav_item ul {
  //background: orange;
  visibility: hidden;
  opacity: 0;
  min-width: 5rem;
  position: absolute;
  transition: all 0.5s ease;
  margin-top: 1rem;
  left: 0;
  display: none;
  top: 38px;
}

.menu_nav_items .menu_nav_item:hover > ul,
.menu_nav_items .menu_nav_item ul:hover{
  visibility: visible;
  opacity: 1;
  display: block
}

.menu_nav_items .menu_nav_item ul li {
  clear: both;
  width: 100%;
}
.menu_nav_items{
  display: flex;
  align-items: center;
}
.menu_nav_item{
  border-right: 0.3px solid #8C8C8C;
  max-width: 180px;
  display: flex;
}

.menu_nav_item:last-child{
  border-right: none;
}
.arrow_down{
  margin-left: 16px;
}

.call_courier{
  border-radius: 4px;
  background: #D40511;;
  font-family: $r_bold;
  font-size: $rb-14;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 16px 28px;
  color: #FFFFFF;
}
.delivery_man{
  margin-right: 16px;
}
.mobile_menu{
  display: none;
}

.secondary_menu_nav_item{
  border-right: none;
  padding: 20px;
}
.secondary_menu_nav_item{
  font-size: $rb-13;
  font-family: $r-medium;
}
.secondary_actions{
  display: flex;
}
.secondary_nav{
  height: 74px;
}
.search_icon{
  margin-right: 24px;
  cursor: pointer;
}
.login{
  display: flex;
  font-size: $rb-16;
  a{
    white-space: nowrap;
    margin-left: 10px;
    margin-bottom: 0;
    line-height: 23px;
  }
}
.language{
  //background: none;
  border: none;
  box-shadow: none;
  font-size: 14px;
  line-height: 14px;
}

.language {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("assets/icons/arrowa.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 10px;
  padding: 1rem;
  padding-right: 2rem;
}

.home_banner{
  height: 610px;
  width: 100%;
  // background: url("assets/images/home_banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.banner_data{
  height: 100%;
  flex-direction: column;
}
.banner_jumbotron{
  font-size: $rb-56;
  line-height: 64px;
  color: $red;
  font-family: $r_bold;
  margin-bottom: 60px;
  width: 60%;
}
.banner_actions{
  width: 46%;
}
.actions_flex{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin-bottom: 16px;
}
.action{
  height: 48px;
  box-shadow: none;
  padding: 16px 32px;
  font-size: $rb-13;
  font-family: $r_medium;
  border-radius: 4px;
}
.calculate{
  background: $red;
  color: #fff;
  border: none;
}
.track{
  background: transparent;
  border: 1px solid;
}
.actions_order{
  width: 100%;
}
.place_order{
  width: 100%;
  height: 48px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $rb-13;
  font-family: $r_medium;
  border-radius: 4px;
  background: transparent;
  border: 1px solid;
}

.brief_info{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 60px
}

.brief_info_item{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}



.footer{
  height: 500px;
  width: 100%;
  background: $dark;
  padding-top: 60px;
  position: relative;
}
.footer_data{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.column_head{
  font-family: $r_bold;
  font-size: $rb-16;
  line-height: $rb-24;
  margin-bottom: 24px;
  color: #fff;
}
.column_nav{
  list-style: none;
  color: $dark4;
  font-size: $rb-16;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  a{
    color: #8C8C8C;
    display: flex;
  }
  a:hover{
    color: $mainBg;
  }
}
.nav_icon{
  margin-right: 20px;
}
.subscribe{
  display: flex;
}
.subscribe_input{
  height: 48px;
  background: #fff;
  border-radius: 4px 0 0 4px;
  padding: 16px 12px;
  border: none;
}
.subscribe_submit{
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $red;
  border-radius: 0 4px 4px 0;
  border: none;
  box-shadow: none;
  padding: 12px;
}
.footer_copyright{
  background: $dark2;
  height: 60px;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.copyright_data{
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}
.copyright_text{
  font-size: $rb-16;
  line-height: $rb-24;
  color: $dark4;
}

@media (max-width: 768px) {
  .main_header_menu{
    display: none;
  }
  .call_courier{
    display: none;
  }
  .mobile_menu{
    display: block;
    cursor: pointer;
  }
  .secondary_nav{
    display: none;
  }
  .banner_jumbotron {
    width: 100%;
  }
  .banner_actions {
    width: 100%;
  }
  .banner_jumbotron{
    font-size: $rb-24;
    line-height: initial;
  }
  .actions_flex{
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .footer_data{
    display: grid;
    grid-template-columns: 1fr;
  }
  .footer{
    height: auto;
  }
  .footer_copyright{
    height: auto;
    position: inherit;
    margin-top: 60px;
    padding-top: 20px;
  }
  .copyright_data{
    flex-direction: column;
    align-items: start;
  }
  .copyright_text{
    margin-bottom: 30px;
  }
}
///////////HOME ENDS///////////


//////////////404 STARTS//////////////
.not_found{
  min-height: 100vh;
}
.not_found_head{
  font-size: $rb-42;
  font-family: $r_bold;
  margin-top: 112px;
}
.not_found_desc{
  font-size: $rb-24;
  font-family: $r_medium;
  line-height: 24px;
  margin-bottom: 8px;
}
.not_found_desc2{
  font-size: $rb-18;
  font-family: $r_regular;
  text-align: center;
}
.not_found_actions{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin-top: 72px;
}
.to_home{
  background: $red;
  color: #fff;
  border: none;
}
.to_contact{
  background: transparent;
  border: 1px solid $red  ;
}

@media (max-width: 768px) {
  .not_found_actions{
    grid-template-columns: 1fr;
    width: 100%;
  }
}
////////////404 END/////////



//////////IMPORT EXPORT SIDEBAR BREADCRUMB/////////////////
.breadcrumbs{
  width: 100%;
  height: 100px;
  background: $light2;
}
.breadcrumbs_info{
  height: 100%;
}
.breadcrumb_items{
  display: flex;
  align-items: center;
}

.breadcrumb_items_name{
  font-size: $rb-14;
  margin-right: 12px;
  white-space: nowrap;
}
.breadcrumb_items_icon{
  margin-right: 12px;
}
.breadcrumb_active{
  font-size: $rb-24;
  margin-top: 12px;
}


.page_content{
  display: grid;
  grid-template-columns: 387px auto;
  gap: 20px;
  margin-top: 30px;
}

.page_navigation{
  padding: 60px;
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 60px;
  height: fit-content;
}
.navigation_group{
  margin-top: 40px;

}
.navigation_head{
  font-size: $rb-24;
  line-height: 28px;
  color: #000;
}
.navigation_group:first-child{
  margin-top: 0;
}
.navigation_item{
  list-style: none;
  margin-top: 20px;
  cursor: pointer;
  font-family: $r_light;
  font-size: $rb-14;
  a{
    color: #000;
    &.active_item{
      color: #D40511;
    }
  }
}
.active_head, .active_item{
  color: $red;
}
.data_head{
  font-size: $rb-24;
  font-weight: 500;
  font-family: $r_medium;
  text-align: center;
  margin-bottom: 20px;
}
.data_desc{
  font-size: $rb-15;
  line-height: 24px;
  h2{
    font-size: 18px;
    margin: 20px 0;
  }
  p{
    font-size: $rb-15;
    line-height: 24px;
    margin-top: 16px;
    margin-bottom: 30px;
  }
  ul{
    padding-left: 27px;
    margin-bottom: 40px;
    li{
      margin-bottom: 16px;
      font-family: $r_medium;
      list-style-type: none;
      position: relative;
    }
    li:before{
      content: '';
      position: absolute;
      left: -26px;
      top: 12px;
      font-size: 1.1em;
      width: 12px;
      height: 2px;
      background: #D40511;
    }
  }
}
.solution{
  ul{
    padding-left: 27px;
    margin-bottom: 40px;
    li{
      //list-style-image: url("assets/icons/check.svg");
      margin-bottom: 16px;
      font-family: $r_medium;
      list-style-type: none;
      position: relative;
    }
    li:before{
      content: '✓';
      position: absolute;
      left: -26px;
      top: 0px;
      font-size: 1.1em;
      color: #00C008;;
      //width: 12px;
      //height: 2px;
      //background: #D40511;
    }
  }
}
@media (max-width: 768px) {
  .page_navigation{
    display: none;
  }
  .page_content{
    display: block;
    margin-top: 30px;
  }
  .breadcrumb_items{
   overflow-x: scroll;
    padding-bottom: 10px;
  }
}

//////////IMPORT EXPORT SIDEBAR BREADCRUMB END////////////







//////////VACANCIES START/////////////////
.page_img{
  border-radius: 4px;
  margin-bottom: 30px;
  width: 100%;
}
.vacancies_list{
  margin-top: 60px;
}
.vacancies_card{
  border: 1px solid #F6F6F9;
  box-sizing: border-box;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  padding: 30px;
  transition: 0.5s all;
  // cursor: pointer;
  position: relative;
  margin-bottom: 40px;
}
.more_info{
  position: absolute;
  bottom: -48px;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s all;
  opacity: 0;
  padding: 12px 20px;
  background: $mainBg;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
  font-family: $r_regular;
  color: #000;
}
.arrow_forward{
  margin-left: 30px;
}
.vacancies_card:hover{
  box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.16);
  transition: 0.5s all;
  .more_info{
    opacity: 1;
  }
}
.card_name{
  font-size: $rb_20;
  line-height: $rb-24;
  margin-bottom: 16px;
}
.card_infos, .card_date{
  display: flex;
  align-items: center;
  margin-right: 60px;
}
.date_icon{
  margin-right: 12px;
}
.date_value{
  font-size: $rb_20;
  line-height: $rb-24;
  color: $dark5;
}
.vacancies_pagination{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
.pagination_data{
  display: flex;
  align-items: center;
}
.pagination_arrow_back{
  margin-right: 20px;
  cursor: pointer;
}
.pagination_arrow_forward{
  margin-left: 20px;
  cursor: pointer;
}
.page_data{
  padding-bottom: 100px;
}
.pagination_count{
  margin: 0 12px;
  cursor: pointer;
}
.video_frame{
  width: 100%;
  height: 460px;
  //margin-bottom: 60px;
}

@media (max-width: 768px) {
  .video_frame{
    width: 100%;
    height: 175px;
    margin-bottom: 60px;
  }
  .card_name{
    font-size: $rb-16;
    line-height: $rb-24;
  }
  .more_info{
    opacity: 1;
    position: relative;
    transform: none;
    margin-top: 30px;
    bottom: inherit;
    left: inherit;
    width: fit-content;
  }
  .card_infos{
    flex-direction: column;
    align-items: start;
  }
  .date_value{
    font-size: $rb-16;
    line-height: $rb-24;
  }
  .card_date{
    margin-bottom: 16px;
  }
}
//////////VACANCIES END/////////////////

//////////VACANCY START/////////////////
.vacancy_icon{
  margin-right: 20px;
}
.vacancy_name{
  font-size: $rb-16;
  line-height: $rb-24;
}
.vacancy_desc, .vacancy_info{
  display: flex;
  align-items: center;
}
.vacancy_info{
  margin-bottom: 30px;
}
.vacancy_desc{
  margin-right: 72px;
  margin-bottom: 0;
}
@media (max-width: 768px) {
  .vacancy_info{
    flex-direction: column;
    align-items: start;
  }
  .vacancy_desc{
    margin-bottom: 30px;
  }
}
//////////VACANCY END/////////////////




//////////ABOUT START/////////////////
.about_banner{
  height: 460px;
  width: 100%;
  // background: url("assets/images/about_banner.jpg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 140px;
}


.about_banner_jumbotron{
  padding: 70px 100px;
  background: $mainBg;
  width: 700px;
  position: absolute;
  bottom: -70px;
}
.jumbotron_header{
  font-family: $r_medium;
  font-size: $rb-32;
  margin-bottom: 30px;
  color: $red;
}
.jumbotron_desc{
  font-size: $rb-15;
}
.about_name{
  font-family: $r_medium;
  font-size: $rb-15;
  margin-bottom: 60px;
  margin-top: 20px;
}
.about_advances{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.advances_item{
  padding: 0 38px;
  border-right: 1px solid #8C8C8C;
}
.advances_item:last-child{
  padding: 0 38px;
  border-right: none
}
.item_count{
  font-family: $r_bold;
  font-size: $rb-24;
  margin-bottom: 16px;
  color: $red;
  text-align: center;
  white-space: nowrap;
}
.item_desc{
  font-family: $r_regular;
  font-size: $rb-16;
  text-align: center;
}
.about_advances{
  margin-bottom: 60px;
}
.dhl_p{
  margin-bottom: 16px;
}
.dhl_offers{
  margin-top: 30px;
  margin-bottom: 40px;
  padding-left: 24px;
}
.dhl_offer{
  list-style-image: url(assets/icons/Line.png);
  font-family: $r_medium;
  font-size: $rb-15;
  line-height: 24px;
  margin-bottom: 16px;
}
.track_offer{
  background: url("assets/images/yellow-background.svg") no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  padding: 40px 30px;
  margin-bottom: 66px;
}
.offer_head{
  font-family: $r_medium;
  font-size: $rb-18;
  //line-height: 24px;
  margin-bottom: 36px;
  color: $red;
}
.track_action{
  padding: 16px 88px;
  display: flex;
  align-items: center;
  font-family: $r_medium;
  font-size: $rb-15;
  line-height: 24px;
  border: none;
  background: #fff;
}
.extra_services{
  font-size: $rb_24;
  font-family: $r_medium;
  text-align: center;
  margin-bottom: 52px;
}
.part_circle{
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #F6F6F9;
  /* Dark/4 */

  border: 1px solid #8C8C8C;
  font-size: $rb-16;
  font-family: $r_bold;
  margin-bottom: 16px;
}
.part_desc{
  font-size: $rb-14;
}
.extra_services_parts{
  margin-bottom: 30px;
}
.services_desc{
  font-size: $rb-15;
  line-height: 24px;
  margin-bottom: 40px;
}
.advances_card{
  padding: 14px 30px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
  transition: 0.5s all;
  height: 104px;
  margin-bottom: 25px;
}
.advances_card:hover{
  background: $mainBg;
}
.card_icon{
  width: 20%;
  border-right: 1px solid #8C8C8C;;
  height: 100%;
}
.card_descrip{
  width: 80%;
  padding-left: 30px;
}
@media (max-width: 768px) {
  .about_banner_jumbotron {
    padding: 30px 16px;
    background: #FFCC00;
    width: 97%;
    position: absolute;
    bottom: -56px;
    left: 0;
  }
  .jumbotron_header{
    font-size: $rb_24;
    margin-bottom: 50px;
  }
  .about_advances {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .advances_item {
    margin-bottom: 56px;
  }
  .track_offer {
    height: 255px;
  }
  .track_action {
    padding: 16px 44px;
    display: flex;
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    border: none;
    background: #fff;
  }
  .part_item {
    margin-bottom: 70px;
  }
  .advances_card {
    padding: 14px 0px;
  }
}
//////////ABOUT END/////////////////


//////////SOCIAL RESPONSIBILITY START////////////////////
.counts{
  margin-top: 90px;
  margin-bottom: 50px;
}
.items_count{
  font-family: $r_bold;
  font-size: $rb-24;
  margin-bottom: 16px;
  color: #027C39;
  text-align: center;
  white-space: nowrap;
}
.advances_items{
  padding: 0 24px;
}

.social_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 50px;
}

.dhl_green_name{
  font-size: $rb-36;
  font-family: $r_medium;
  color: $red;
  margin-bottom: 12px;
}
.green_name{
  font-size: $rb-18;
  font-weight: $r_bold;
  margin-bottom: 12px;
}

.green_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 30px;
}

.video_img{
  object-fit: cover;
  margin-bottom: 60px;
  width: 100%;
}

.collapse_head{
  font-size: $rb-36;
  margin-top: 60px;
  margin-bottom: 20px;
  text-align: center;
}

.collapse_text{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 30px;
}

.advanced_div{
  padding: 12px 16px;
  width: 100%;
  background: #F6F6F9;
  margin-bottom: 30px;
}
.advanced_name{
  font-family: $r_bold;
  font-size: $rb-18;
  line-height: $rb-24;
  margin-bottom: 6px;
}
.advanced_desc, .check_name{
  font-size: $rb-15;
  line-height: $rb-24;
}
.specials_item{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.check{
  margin-right: 20px;
}
.collapsing{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid;
  border-top: 1px solid;
  cursor: pointer;
  padding-right: 16px;
}
.fieldsetContainer {
  height: 0;
  overflow: hidden;
  transition: height 400ms linear;
}

[id|=toggle] {
  display: none;
}

[id|=toggle]:checked ~ .fieldsetContainer {
  height: auto;
  margin-top: 15px;
  color: #8C8C8C;
  margin-bottom: 16px;
}

label .arrow-dn { display: inline-block; }
label .arrow-up { display: none; }

[id|=toggle]:checked ~ label .arrow-dn { display: none; }
[id|=toggle]:checked ~ label .arrow-up { display: inline-block; }


@media (max-width: 768px) {
  .advances_items {
    margin-bottom: 32px;
  }
  .dhl_green_name, .collapse_head {
    font-size: 24px;
  }
}
//////////SOCIAL RESPONSIBILITY END////////////////////
/////////THIRD COUNTRY START/////////////////////////////////
.stepper_form{
  padding: 60px 20px;
  margin-top: 30px;
  background: $light2;
}
/////////////STEPPER////////
:root {
  --s-width: 900px;
  --s-gutter: 2.5rem;
  --c-background: rgb(0, 0, 0);
  --c-accent: #FFCC00;;
}
/////
// STEPPER CSS
///////

.stepper {
  $root: &;
  --s-stepper-bullet: 32px;
  --s-stepper-bullet-half: calc( var(--s-stepper-bullet) / 2 );
  --step-transition: background .5s, color .5s;
  // all colors are define for the active state
  --step-content: '✔︎';
  --step-color: #FFCC00;
  --step-bar-bg: var(--c-accent);
  --step-bullet-bg: var(--step-bar-bg);
  --step-bullet-color: white;
  --setp-bar-height: 4px;
  counter-reset: current-step;
  position: relative;
  z-index: 1;

  // don't know
  &__input {
    counter-increment: steps;
    display: none;

  }
  &__step {
    counter-increment: current-step;

    // everything beyond the current step is deactivated
    #{$root}__input:checked ~ & {
      --step-color: black;
      --step-bar-bg: #d2d2d2;
      --step-bullet-bg: white;
      --step-bullet-color: #d2d2d2;
      --step-content: counter(current-step);
      --setp-bar-height: 4px;
    }
    // This is the current step
    // • restore active colors & content
    #{$root}__input:checked + & {
      --step-bullet-bg: #FFCC00;
      --step-bullet-color:#000;
      --step-color: #000;
      --setp-bar-height: 4px
    }
  }
  &__content {
    display: none;
  }
  &__button {
    position: relative;
    text-align: center;
    color: #000000;
    display: flex;
    flex-direction: column;
    font-size: 14px;

    // bullet
    &::before {
      content: var(--step-content);
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto var(--s-stepper-bullet-half);
      height: var(--s-stepper-bullet);
      width: var(--s-stepper-bullet);
      border-radius: var(--s-stepper-bullet);
      transition: var(--step-transition);
      background: var(--step-bullet-bg);
      color: var(--step-bullet-color);
      font-size: 12px;
    }

    // progress bar
    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: var(--setp-bar-height);
      background: var(--step-bar-bg);
      // transition: var(--step-transition);
      // transform: translate(0, -50%);
      top: 35%;
      left: 50%;
      z-index: -1;

      // connecting lines are always steps.length - 1
      #{$root}__step:last-child & {
        display: none;
      }
    }
  }

  // we can also use CSS flexbox.
  // • but when not enough space
  //   columns won't be  respected anymore
  &--flexbox {
    display: flex;

    #{$root}__step {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
    }
  }
}
.stepper{
  margin-bottom: 90px;
}
.form_head{
  font-size: $rb-16;
  line-height: $rb-24;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}
.two_inputs{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:20px;
  margin-bottom: 34px;
}
.five_inputs{
  display: grid;
  grid-template-columns:repeat(5, minmax(0, 1fr));
  gap:20px;
  margin-bottom: 34px;
}
.form_input{
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  background: #F6F6F9;
  padding-left: 12px;
  font-size: $rb-16;
  line-height: $rb-24;
  width: -webkit-fill-available;
}
.form_input::placeholder{
  font-size: $rb-16;
  line-height: $rb-24;
  color: #00000060;
}
.number_input{
  display: flex;
}
.country_code{
  height: 56px;
  background: #fff;
  border: 1px solid #8C8C8C;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-right: none;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  width: 80px;
  cursor: pointer;
  padding: 0 10px;
  font-size: $rb-16;
  line-height: $rb-24;
  color: #00000060;
}
.number{
  border-left: 0 solid;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.form_submit{
  padding: 16px 32px;
  position: absolute;
  border-radius: 4px;
  font-size: $rb-13;
  font-family: $r_medium;
  background: $red;
  border: none;
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.form_submit_parent{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
}
.single_input{
  margin-bottom: 34px;
}
.radio,
.radio__label,
.radio__label:after,
.radio__label:before {
  box-sizing: border-box;
}

// For demonstration only
.radio__container {
  &:nth-child(1) {
    padding-bottom: 35px;
    margin-bottom: 28px;
    border-bottom: 1px dotted rgb(219, 219, 219);
  }
}

.radio__container {
  display: flex;
  .radio-inline {
    display: inline-block;
    margin-right: 10px;
  }

  .radio {
    display: inline;
    opacity: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    -webkit-appearance: none;
  }

  .radio__label {
    display: inline-block;
    height: 50px;
    position: relative;
    padding: 15px 10px 15px 28px;
    cursor: pointer;
    vertical-align: bottom;
    color: rgba(0, 0, 0, .54);
    font-size: $rb-16;
    line-height: $rb-24;
    transition: color 200ms ease;
    margin-right: 40px;

    &:before, &:after {
      position: absolute;
      content: "";
      border-radius: 50%;
      transition: transform 200ms ease, border-color 200ms ease;
    }

    &:before {
      left: 0;
      top: 15px;
      width: 20px;
      height: 20px;
      border: 2px solid rgb(219, 219, 219);
    }

    &:after {
      top: 20px;
      left: 5px;
      width: 10px;
      height: 10px;
      transform: scale(0);
      background-color: $mainBg;
    }

    &:hover {
      color: $mainBg;

      &:before {
        border-color: $mainBg;
      }
    }
  }

  .radio:checked {
    & + .radio__label {
      color: rgba(0, 0, 0, .87);
    }

    & + .radio__label:before {
      border-color: $mainBg;
    }

    & + .radio__label:after {
      transform: scale(1);
    }
  }
}
@media (max-width: 768px) {
  .two_inputs{
    grid-template-columns: 1fr;
  }
  .five_inputs{
    grid-template-columns:repeat(2, minmax(0, 1fr));
  }
  .radio__container{
    flex-direction: column;
  }
  .form_submit_parent{
    position: relative;
  }
  .form_submit{
    width: 100%;
  }
}
.info_icon{
  margin-left: 8px;
}
/////////THIRD COUNTRY END//////////////////////////////////




/////////GOMRUK RESMILESDIRME START//////////////////////////////////
.info_input{
  margin-bottom: 34px;
  background: url("assets/icons/info.svg") no-repeat calc(100% - 12px);
  padding-left: 10px;
  &.no_info{
    background: none;
  }
  &~.icon{
    border: 1px solid transparent;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 16px;
    right: 13px;
    cursor: pointer;
  }
}

.relative{
  position: relative;
}

/////////GOMRUK RESMILESDIRME END//////////////////////////////////




/////////QADAGALAR START //////////////////////////////////
.no_margin{
  margin: 0;
}


/////////QADAGALAR END//////////////////////////////////


/////////SUCCESS START////////////////////////////////
.success{
  padding: 87px 60px;
  background: linear-gradient(0deg, #FFCC00 0%, #FFCC00 29.97%, #FFE57F 78.91%, #FFF0B2 99.88%);
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.success_head{
  font-size: $rb-24;
  margin-bottom: 16px;
}
.success_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 60px;
}
.success_check{
  margin-right: 60px;
}
.success_submit{
  justify-content: start !important;
}
/////////SUCCESS END//////////////////////////////////
.router_item{
  display: block;
}

.collap{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  cursor: pointer;
  padding: 18px;
  background: #F6F6F9;
  margin-bottom: 20px;
}

.fieldsetContainers {
  height: 0;
  overflow: hidden;
  transition: height 400ms linear;
}

[id|=colllapse] {
  display: none;
}

[id|=colllapse]:checked ~ .fieldsetContainers {
  height: auto;
  //margin-top: 15px;
  padding: 18px;
  background: #F6F6F9;
}

label .arrow-dn { display: inline-block; }
label .arrow-up { display: none; }

[id|=colllapse]:checked ~ label .arrow-dn { display: none; }
[id|=colllapse]:checked ~ label .arrow-up { display: inline-block; }
.collap_desc{
  font-size: $rb-15;
  line-height: $rb-24;
}

.collapsible_list{
  margin-top: 30px;
}

.count{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $mainBg;
  font-size: $rb-15;
  line-height: $rb-24;
  border-radius: 50%;
  margin-right: 30px;
}
.expand{
  display: flex;
  align-items: center;
}

.collap_head{
  font-size: $rb-15;
  line-height: $rb-24;
  font-family: $r_bold;
}

.info_bar{
  background: #DDF6FF;
  border: 1px solid #70CBEE;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  font-size: $rb-14;
  line-height: $rb-24;
  align-items: center;
  margin-bottom: 30px;
  width: 60%;
}
.info_icon_light{
  background: #70CBEE;
  border-radius: 4px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.opportunities{
  padding-bottom: 90px;
}
.opportunities_head{
  font-family: $r_medium;
  font-size: $rb-36;
  margin-bottom: 16px;
  text-align: center;
  margin-top: 60px;
}

.opportunities_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 60px;
  text-align: center;
}
.opportunities_list{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap:20px
}

.opportunities_item{
  background: #FFFFFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.06);
  border-radius: 0 0 4px 4px;
  transition: 0.4s all;
}

.opportunities_img{
  height: 190px;
  margin-bottom: 20px;
  object-fit: cover;
  overflow: hidden;
  img{
    width: 100%;
  }
}
.opportunities_info{
  padding: 0 30px 30px;
}
.opp_name{
  font-family: $r_medium;
  font-size: 18px;
  margin-bottom: 10px;
}
.opp_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 32px;
}
.opp_action{
  display: flex;
  align-items: center;
  padding: 16px 20px;
  font-size: $rb-16;
  line-height: $rb-16;
  margin-left: auto;
  margin-right: auto;
  border: none;
  box-shadow: none;
  outline: none;
  background: none;
  transition: 0.4s all;
  border-radius: 4px;
}
.forward{
  margin-left: 24px;
}
.opportunities_item:hover{
  box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.16);
  .opp_action{
    background: $mainBg;
    border-radius: 4px;
  }
}

@media (max-width: 768px) {
  .opportunities_head{
    font-size: 24px;
  }
  .opportunities_list{
    display: grid;
    grid-template-columns: 1fr;
    gap:20px
  }
  .opp_action{
  background: $mainBg;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    a{
      color: #000;
    }
  }
}
.kiv_tab{
  display: flex;
  align-items: center;
  border-radius: 4px;
justify-content: center;
  overflow: hidden;
  margin: 30px 0;
}
.kiv_tab_item{
  padding: 20px 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00000060;
  border: none;
  background: none;
  box-shadow: none;
  background: #EFEFEF;
  border-radius: 4px;
}
.kiv_tab_item:first-child{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.kiv_tab_item:last-child{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.kiv_tab_item:nth-child(2){
  border-radius: 0;
}
.selected_tab_item{
  background: $mainBg;
  color: black;
  border-radius: 4px;
}

.kiv_items{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px
}

.kiv_item{
  background: #FFFFFF;
  /* Light/2 */

  border: 1px solid #F6F6F9;
  box-sizing: border-box;
  /* Low Shadow */
  width: 387px;
  height: 387px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.06);
  padding: 30px 20px;
  position: relative;
  transition: 0.4s all;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}
.kiv_item_img{
  width: 48px;
  height: 48px;
}
.download_button{
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 16px 35px;
  background: none;
  box-shadow: none;
  border: none;
  transition: 0.4s all;
  font-size: $rb_13;
  font-family: $r_medium;
  margin-top: 60px;
  color: #fff;
  border-radius: 4px;
}

.download_icon{
  margin-right: 30px;
}

.kiv_item:hover{
  background: $mainBg;
  .download_button{
    background: $red;
    color: white;
  }
}
#kiv_select{
  display: none;
  padding: 20px 12px;
  width: 100%;
  background: #F6F6F9;
  /* Dark/4 */

  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 16px;
  line-height: 19px;
  color: #00000060;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .kiv_items{
    grid-template-columns: 1fr;
  }
  .kiv_tab{
    display: none;
  }
  #kiv_select{
    display: block;
  }
  .download_button{
    background: $red;
    color: white;
  }
}

//////////////////SPRINT 2//////////////////
.overlay{
  width: 100%;
  height: 100%;
  background: #00000060;
  opacity: 0;
}

.kiv_hover_action{
  position: absolute;
  top: 50%; right: 50%;
  transform:  translate(50%,-50%);
  opacity: 0;
  transition: 0.4s all;
}
.error{
  border: 1px solid #D40511;
  margin-bottom: 8px;
}
.error_icon{
  margin-right: 8px;
}
.kiv_photo{
  padding: 0;
}
.kiv_photo:hover{
  cursor: pointer;
  .overlay{
    opacity: 1;
  }

  .kiv_hover_action{
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .kiv_item{
    width: 100%;
    height: 288px;
  }
}

.overlay{
  width: 100%;
  height: 100%;
  background: #00000060;
  opacity: 0;
}

.kiv_hover_action{
  position: absolute;
  top: 50%; right: 50%;
  transform:  translate(50%,-50%);
  opacity: 0;
  transition: 0.4s all;
}
.error{
  border: 1px solid #D40511;
  margin-bottom: 8px;
}
.error_icon{
  margin-right: 8px;
}
.kiv_photo{
  padding: 0;
}
.kiv_photo:hover{
  .overlay{
    opacity: 1;
  }

  .kiv_hover_action{
    opacity: 1;
  }
}


.collap_faq{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  cursor: pointer;
  padding: 18px;
  background: #FFFFFF;
  /* Dark/4 */

  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 12px;
}

.fieldsetContainersFaq {
  height: 0;
  overflow: hidden;
  transition: height 400ms linear;
}

[id|=colllapse] {
  display: none;
}

[id|=colllapse]:checked ~ .fieldsetContainersFaq {
  height: auto;
  //margin-top: 15px;
  padding: 18px;
  background: none;
}
[id|=colllapse]:checked ~ .collap_faq{
  background: $mainBg;
  border: none;
}
label .arrow-dn { display: inline-block; }
label .arrow-up { display: none; }

[id|=colllapse]:checked ~ label .arrow-dn { display: none; }
[id|=colllapse]:checked ~ label .arrow-up { display: inline-block; }
.collap_desc{
  font-size: $rb-15;
  line-height: $rb-24;
}

.collapsible_faq{
  margin-top: 30px;
}

.toggle_collapse{
  font-size: 38px;
}
.collapsible{
  margin-bottom: 16px;
}

.download_item{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 12px;
  margin-bottom: 16px;
}
.download_item_selected{
  background: $mainBg;
  border: none;
}
.download_action{
  display: flex;
  align-items: center;
}
.download_name{
  font-size: $rb-18;
  line-height: $rb-24;
  color: $mainBg;
  margin-right: 20px;
}
.download_name_selected{
  color: #000;
}

@media (max-width: 768px) {
  .download_item{
    flex-direction: column;
    align-items: flex-start;
  }
  .collap_head{
    //margin-bottom: 70px;
  }
  .page_data{
    margin-top: 40px;
  }
}
.corporative{
  height: 500px;
  width: 100%;
  margin-bottom: 90px;
}

.corporative_content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  padding: 40px 0;
  height: 100%;
}

.corporative_header{
  font-family: $r_medium;
  font-size: $rb-36;
  margin-bottom: 46px;
}
.yellow{
  color: $mainBg;
}
.corporative_desc{
  font-size: $rb-16;
  line-height: $rb-24;
  margin: 30px 0;
}
.corporative_contact{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.phone{
  margin-right: 30px;
}
.phone_icon{
  margin-right: 16px;
}
.contact_form_head{
  font-size: $rb-24;
  margin-bottom: 60px;
}

.form_first_step_corp{
  width: 70%;
  margin: 0 auto;
}
.corp_head{
  text-align: center;
}
@media (max-width: 768px) {

  .form_first_step_corp{
    width: 100%;
    margin: 0 auto;
  }
  .corporative_content{
    grid-template-columns: 1fr;
    padding: 30px 12px;
  }
  .corporative_header{
    font-size: 24px;
  }
}

.contact_address{
  background: #F6F6F9;
  border-radius: 4px;
  padding: 60px 30px;
  height: fit-content;
}

.address_name{
  font-family: $r_bold;
  font-size: $rb-24;
  margin-bottom: 26px;
  text-align: center;
}
.address_item{
  margin-bottom: 40px;
}
.form_textarea{
  height: 116px;
}

.message{
  margin-bottom: 10px;
}
.contact_content{
  margin-top: 100px;
}
.contact_banner{
  height: 450px
}
.address_item:last-child{
  margin-bottom: 0;
}
.check_icon{
  margin-right: 16px;
}
@media (max-width: 768px) {
  .contact_banner{
    height: 200px;
  }
  .contact_content{
    margin: 0;
  }
  .contact_address{
    margin-bottom: 60px;
  }
}

/////////////////////SPRINT 4////////////////////////
.brief_info{
  margin-top: 60px;
  margin-bottom: 60px;
}
.home_services{
  padding: 90px 0;
 background: #F6F6F9;;
}

.home_services_head{
  font-family: $r_medium;
  font-size: $rb-36;
  margin-bottom: 16px;
  text-align: center;
}

.home_services_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  text-align: center;
  margin-bottom: 60px;
}

.home_services_items{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.home_services_item{
  width: 32%;
  //height: 100px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.4s all;
  background: #fff;
  margin-bottom: 20px;
  margin-right: 20px;
  height: 380px;
}
.home_services_item:nth-child(3n){
  margin-right: 0;
}
//.services_action{
//  opacity: 0;
//}
.home_services_item:hover{
  .services_action{
   background: #fff;
  }
  background: $mainBg;
}
.name_height{
  min-height: 56px;
}

.home_services_item_icon{
  height: 90px;
  width: 90px;
  margin-bottom: 20px;
}
.home_services_item_name{
  font-family:  $r_medium;
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 32px;
  text-align: center;

}

.home_services_item_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 16px;
  text-align: center;
}
.opportunities_container{
  display: flex;
  align-items: start;
  justify-content: space-between;
  padding-top: 120px;
  padding-bottom: 120px;
}
.opportunities_brief_name{
  font-family: $r_medium;
  font-size: $rb-36;
  margin-bottom: 16px;
}
.opportunities_brief_desc{
  font-size: $rb-15;
  line-height: $rb-24;
}
.opportunities_slider{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.opportunities_brief{
  width: 25%;
}
.opportunities_slider{
  width: 75%;
}

.about{
  padding: 60px 0;
  background: #F6F6F9;
}

.about_dhl_head{
  font-size: $rb-18;
  line-height: $rb-24;
  margin-bottom: 16px;
}
.about_dhl_section_name{
  font-size: $rb-36;
  font-family: $r_medium;
  margin-bottom: 20px;
}
.about_dhl_section_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 50px;
}

.stepper_head{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 50px;
  font-family: $r_medium;
  text-align: center;
}

.divided {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px dashed $mainBg;
  margin: 5px
}


.divider_icon{
  margin-bottom: 32px;
}

.divider_name{
  font-size: $rb-15;
  line-height: $rb-24;
}

.stepper_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .brief_info{
    grid-template-columns: 1fr;
    gap: 0;
  }
  .brief_info_item{
    margin-bottom: 60px;
  }
  .home_services_items{
    flex-direction: column;
  }
  .home_services_item{
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
  .opportunities_container{
   flex-direction: column;
    padding-top:60px;
    padding-bottom: 60px;
  }
  .opportunities_brief, .opportunities_slider{
    width: 100%;
  }
  .divided{
    flex-direction: column;
    align-items: start;
  }
  .divider_icon {
    margin-bottom: 0;
    margin-right: 16px;
  }
  .mobile_fix{
    margin-bottom: 32px;
  }
  .divider{
    flex-grow: 1;
    border-bottom: 1px dashed #FFCC00;
    margin: 37px;
    /* height: 100px; */
    /* margin-left: 0; */
    margin-top: 17px;
    width: 100px;
    margin-right: 2px;
    transform: rotate(
        90deg);
  }
}

/////////BURGER MENU//////////////////
.menu_head{
  padding: 16px;
  background: #F6F6F9;
}

.menu_search{
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  height: 46px;
  border: none;
  background: #fff url("assets/icons/search.svg") no-repeat 10px 10px;
  padding-left: 47px;
}
.menu_search::placeholder{
  font-size: 16px;
  line-height: 19px;
  color: #00000030;
}
.menu_content{
  padding: 35px 16px;
}
.login_lang{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.menu_item_selected,.menu_item_non_selected{
  padding: 17px 0;
}

.menu_name{
  font-family: $r_medium;
  font-size: $rb-13;
}

.menu_opened_items{
  li{
    list-style: none;
    font-size: $rb-15;
    line-height: $rb-24;
    margin: 32px 16px;
  }
  li:last-child{
    margin-bottom: 0;
  }
  li:first-child{
    margin-top: 20px;
  }
}

.upside{
  transform: rotate(180deg);
}
.menu_items{
  position: relative;
  height: 100%;
}
.call_courier_mobile{
  border-radius: 4px;
  background: #D40511;;
  font-family: $r_bold;
  font-size: $rb-14;
  box-shadow: none;
  border: none;
  outline: none;
  padding: 16px 28px;
  color: #FFFFFF;
  width: 100%;
  //position: absolute;
  bottom: 0;
}

.mob_menu{
   width: 100vw;
  height: 100%;
  overflow: auto;
  display: none;
   position: fixed;
   top: 0px;
  // z-index: 9999999999;
  // position: fixed;
  // top: 0px;
  // left: -100vw;
  // transition: .3s ease-in-out;
}

.menu_content{
  //height: calc(100vh - 80px);
}

@media (max-width: 768px) {
  .mob_menu{
    &.active{
      display: block;
      background: #fff;
      z-index: 10000;
    }
  }
}



//////////CALCULATE////////////////////
.calculate_container{
  padding-top: 40px;
}

.three_inputs{
  display: grid;
  grid-template-columns: 1fr 0.5fr 0.5fr;
  gap: 20px
}
.info_input{
  margin-bottom: 34px;
  // background: url("assets/icons/info.svg") no-repeat calc(100% - 12px) #F6F6F9;;// here
  padding-left: 10px;
}
.code_check{
  display: flex;
  align-items: center;
  margin-top: 34px;
}
.code{
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.check_action{
  padding: 18px 30px;
  height: 56px;
  background: #000000;
  color: #fff;
  font-size: $rb-16;
  border: none;
  box-shadow: none;
  border-radius: 0 4px 4px 0;
}

.document{
  width: 100%;
  padding: 60px 0;
  background: $mainBg;
  margin-top: 60px;
}

.document_content{
  width: 70%;
  margin: 0 auto;
}

.document_tab_item{
  padding: 20px 50px;
  border: 1px solid #8C8C8C;
  background: #D2D2D2;
  cursor: pointer;
}
.document_tab_item_selected{
  border-top: 2px solid #D40511;
  background: #fff;
}
.document_tab_item_icon{
  margin-right: 18px;
}
.document_tab_item_name{
  font-size: $rb-18;
}

.document_tab_content{
  padding: 60px 102px;
  background: #fff;
}
.weight_calculator{
  padding: 30px 20px;
  background: #000;
  display: flex;
  align-items: center;
}

.calculate_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  color: #fff;
}
.calculator_input{
  width: fit-content;
  margin-right: 16px;
}

.calculator_actions{
  width: 70%;
  margin: 60px auto 0;
}
.cancel{
  border: 1px solid $red;
  color: $red;
}

@media (max-width: 768px) {
  .three_inputs{
    grid-template-columns: 1fr;
  }
  .info_input{
    margin-bottom: 0;
    &~.icon{
      border: 1px solid red;
      top: 16px;
      right: 13px;
    }
  }
  .form_head{
    margin-top: 20px;
  }
  .document {
    width: 100%;
    padding: 0;
    background: #fff;
    margin-top: 60px;
  }
  .document_content{
    width: 100%;
  }
  .document_tab_content{
    padding: 0;
  }
  .weight_calculator{
    flex-direction: column;
    margin-top: 56px;
  }
  .calculator_input{
    width: 100%;
    margin-bottom: 16px;
  }
  .document_tab_item {
    padding: 20px 34px;
    width: 100%;
  }
  .calculator_actions{
    width: 100%;
  }
}

.document_list{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px
}
.document_item{
  padding: 25px 30px;
  background: #F6F6F9;
  border-radius: 4px;
}
.document_icon{
  margin-right: 22px;
}

.weight_name{
  font-size: 11px;
  margin-bottom: 4px;
}
.setting_icon{
  margin-right: 10px;
}

.added_document{
  margin: 60px auto 0;
  width: 70%;
}
.add_new_doc{
  background: #FFF1B8;
  border-radius: 4px;
  padding: 25px 30px;
}
.add_new_doc_name{
  margin: 0 16px;
  font-size: $rb-16;
}

@media (max-width: 768px) {
  .added_document{
    width: 100%;
  }

  .document_list{
    grid-template-columns: 1fr;
  }
}


.trapezoid {
  width: 96%;
  margin: 0 auto;
  height: 54px;
  background: url(assets/icons/redLine.svg) $mainBg;
  background-size: 105%;
  background-position: -9px 16px;
  background-repeat: no-repeat;
  transform: perspective(10px) rotateX(
      1deg);
}

.calculating_parcel{
  height: 180px;
  padding: 40px 20px 4px 20px;
  background: url(assets/icons/redline1.svg) #EEBD00; ;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 0px calc(100% - 32px);
  position: relative;
  margin-bottom: 30px;
}

.calc_inputs{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px
}

.sm_input{
  width: 100%;
}

.calc_valid{
  color: $red;
  font-size: $rb-14;
  line-height: $rb-24;
  position: absolute;
  bottom: 0;
}

.info_icon{
  margin-right: 18px;
}
.calc_info{
  font-size: 14px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .calc_inputs{
    grid-template-columns: 1fr;
    gap: 0
  }
.trapezoid{
  display: none;
}
  .sm_input{
    margin-bottom: 20px;
  }
  .calculating_parcel {
    height: auto;
    padding: 30px 16px 36px 16px;
    background-position: 0px calc(100% - 40px);
  }
  .calc_valid {
    bottom: 10px;
  }
}

.delivery_methods{
  margin: 90px auto ;
  width: 70%;
}



.collap_delivery{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 26px;
  background: #fff;
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
}

.fieldsetContainersDeliveryMethod {
  height: 0;
  overflow: hidden;
  transition: height 400ms linear;
}

[id|=colllapse_delivery_method] {
  display: none;
}

[id|=colllapse_delivery_method]:checked ~ .fieldsetContainersDeliveryMethod {
  height: auto;
  //margin-top: 15px;
  padding: 18px;
  background: #F6F6F9;
  /* Dark/4 */

  border: 1px solid #8C8C8C;
  border-top: none;
}
[id|=colllapse_delivery_method]:checked ~ .collap_faq{
  background: $mainBg;
}
label .arrow-dn { display: inline-block; }
label .arrow-up { display: none; }

[id|=colllapse_delivery_method]:checked ~ label .arrow-dn { display: none; }
[id|=colllapse_delivery_method]:checked ~ label .arrow-up { display: inline-block; }
.collap_desc{
  font-size: $rb-15;
  line-height: $rb-24;
}

.delivery_method_item{
  margin-top: 30px;
}

.toggle_collapse{
  font-size: 38px;
}
.collapsible{
  margin-bottom: 16px;
}

.delivery_icon{
  margin-right: 40px;
}
.delivery_method_info_name{
  font-size: $rb-24;
  line-height: 28px;
  margin-bottom: 16px;
}

.delivery_method_info_ul {
  list-style: none;
  padding-left: 24px;
}

.delivery_method_info_ul li::before {
  content: "\2022";
  color: $mainBg;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  font-size: 13px;
  margin-bottom: 12px;
}

.time_icon{
  margin-right: 12px;
}
.delivery_name{
  font-size: $rb-13;
  margin-bottom: 14px;
}
.delivery_count{
  font-size: $rb-13;
  color: $red;
}
.delivery_time{
  margin-top: 24px;
}
.delivery_price{
  font-family: $r_bold;
  font-size: $rb-24;
  margin-bottom: 56px;
  color: $red;
  text-align: right;
}
.delivery_order{
  background: #D40511;
  border-radius: 4px;
  box-shadow: none;
  border:  none;
  font-size: $rb-16;
  color: #fff;
  padding: 16px 23px;
}
.arrow_icon{
  margin-left: 16px;
}
.expand_delivery{
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
}

.delivery_method_actions{
  position: relative;
}


.changeArrowDelivery{
  display: inline-flex !important;
  margin-left: auto;
  position: absolute;
  bottom: -60px;
  right: 0;
  color: $red;
}
.down_icon{
  margin-left: 16px;
}
.delivery_method_expanded{
  display: flex;
  justify-content: space-between;
  padding-right: 56px;
}

.expand_head{
  font-size: 16px;
  line-height: 19px;
  color: #515151;
  margin-bottom: 16px;
}

.expanded_ul{
  padding-left: 30px;
}

.expanded_ul li{
  font-size: 13px;
  line-height: 15px;
  color: #515151;
  margin-bottom: 12px;
}

.delivery_methods_item{
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .delivery_methods{
    width: 100%;
  }
  .delivery_icon{
    display: none;
  }
  .expand_delivery{
    flex-direction: column;
  }
  .delivery_price {
    font-size: 24px;
    margin-bottom: 24px;
    text-align: left;
    margin-top: 30px;
  }
  .collap_delivery{
    padding-bottom: 60px;
  }
  .changeArrowDelivery {
    bottom: -43px;
    left: 0;
  }
}


/////////////////SPRINT 5////////////////
.receipt{
  margin-top: 60px;
}
.receipt_stepper{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.stepper__button::before {
  border: 1px solid;
}

.receipt_first_step{
  background: #F6F6F9;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0;
}
.sender_form{
  border-right: 1px solid #8C8C8C;;
}

.sender_form, .receiver_form{
 padding: 30px;
  margin-bottom: 60px;
}

.form_header{
  font-size: $rb-24;
  line-height: $rb-24;
  margin-bottom: 60px;
  text-align: center;
}
.phone_form{
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  gap: 20px;
}

.phone_type{
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  height: 56px;
  background: #F6F6F9 ;
  padding-left: 12px;
  font-size: $rb-16;
  line-height: $rb-24;
  width: -webkit-fill-available;
}
.phone_type::placeholder{
  font-size: $rb-16;
  line-height: $rb-24;
  color: #00000060;
}

.adjust_margin{
  margin-bottom: 34px;
}

.receipt_second_step{
  background: #F6F6F9;
  padding-bottom: 90px;
}
.receipt_third_step{
  background: #F6F6F9;
  padding-bottom: 90px;
}
.receipt_fourth_step{
  background: #F6F6F9;
  padding-bottom: 90px;
}
.customer_data{
  margin: 6px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  margin-bottom: 60px;
}
.sender_head{
  font-size: 24px;
  text-align: center;
  margin-bottom: 60px;
}
.sender_data_name{
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #D2D2D2;
  margin-bottom: 20px;
}
.customer_content_icon{
  margin-right: 32px;
}
.customer_content{
  display: flex;
  align-items: center;
  div p{
    font-size: $rb-16;
    line-height:  $rb-16;
    text-align: left;
    color: #D2D2D2;
  }
}

.edit_button{
  padding: 17px 32px;
  display: flex;
  align-items: center;
  border: none;
  background: #F6F6F9;
  box-shadow: none;
  font-size: $rb-16;
  margin: 0;
  border-radius: 4px;
}

.edit_icon{
  margin-right: 16px;
}

.sender_tabs{
  width: 70%;
  margin: 0 auto;
  display: flex;
  margin-bottom: 60px;
}


.sender_tabs .option{
  padding: 16px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: #EFEFEF;
  border-radius: 4px;
}
.sender_tabs .option .dot{
  height: 20px;
  width: 20px;
  background: #d9d9d9;
  border-radius: 50%;
  position: relative;
}
.sender_tabs .option .dot::before{
  position: absolute;
  content: "";
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  background: $mainBg;
  border-radius: 50%;
  opacity: 0;
  transform: scale(1.5);
  transition: all 0.3s ease;
}
#option-1, #option-2,#option-3, #option-4{
  display: none;
}
#option-1:checked:checked ~ .option-1,
#option-2:checked:checked ~ .option-2,#option-3:checked:checked ~ .option-3,
#option-4:checked:checked ~ .option-4{
  border-color:$mainBg;
  background: $mainBg;
}
#option-1:checked:checked ~ .option-1 .dot,
#option-2:checked:checked ~ .option-2 .dot, #option-3:checked:checked ~ .option-3 .dot,
#option-4:checked:checked ~ .option-4 .dot{
  background: #fff;
}
#option-1:checked:checked ~ .option-1 .dot::before,
#option-2:checked:checked ~ .option-2 .dot::before, #option-3:checked:checked ~ .option-3 .dot::before,
#option-4:checked:checked ~ .option-4 .dot::before{
  opacity: 1;
  transform: scale(1);
}
.sender_tabs .option span{
  font-size: 20px;
  color: #000;
}


.doc_icon{
  margin-right: 16px;
}


.form_sm{
  width: 70%;
  margin: 0 auto;
  .form_item{
    margin-bottom: 32px;
  }
}


.form_warning{
  padding: 12px 16px;
  border-radius: 4px;
  border: 1px solid #8C8C8C;
}

.box {
  width: 300px;
  margin: 25px 0;
  display: flex;
  align-items: center;
  user-select: none;

  label {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    position: absolute;
    z-index: 10;
    padding-left: 50px;
    cursor: pointer;
  }

  input {
    opacity: 0;
    visibility: hidden;
    position: absolute;

    &:checked {

      ~ .check {
        border-color: $mainBg;
        box-shadow: 0px 0px 0px 15px $mainBg inset;

        &::after {
          opacity: 1;
          transform: scale(0.8);
        }
      }
    }
  }

  .check {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 4px;
    background-color: #FFF;
    border: 1px solid $mainBg;
    transition: all 0.15s cubic-bezier(0, 1.05, 0.72, 1.07);

    &::after {
      content: '';
      width: 80%;
      height: 80%;
      opacity: 0;
      z-index: 4;
      position: absolute;
      transform: scale(0);
      //background-size: 50%;
      //background-image: url('https://s6.picofile.com/d/8392306668/bacc888c-bed7-41a9-bf24-f6ff0718f471/checkmark.svg');
      //background-repeat: no-repeat;
      //background-position: center;
      transition-delay: 0.2s !important;
      transition: all 0.25s cubic-bezier(0, 1.05, 0.72, 1.07);
    }
  }
}

.warning_info{
  margin: 30px 0;
}

.info_bar{
  width: 100%;
  background: #DDF6FF;
  border: 1px solid #70CBEE;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 14px;
}
.info_icon{
  margin-right: 12px;
  margin-left: 0;
  width: 48px;
  height: 100%;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #70CBEE;
}


.radio_box > input[type="radio"] {
  display: none;
}
.radio_box > input[type="radio"] + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: gray;
}
.radio_box > input[type="radio"]:checked + * {
  //color: $mainBg;
}
.radio_box > input[type="radio"]:checked + *::before {
  background: $mainBg;
  border-color: $mainBg;
}
.radio_box{
  margin-right: 32px;
}

.radios{
  margin-top: 16px;
}
.margin_adjust{
  margin-top: 60px;
}


.parcel_info{
  background: #FFF1B8;
  display: flex;
  justify-content: space-between;
}
.parcel_count{
  background: $mainBg;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.parcel_info{
  width: 95%;
  margin: 0 auto;
}


.parcel_copy{
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background: #fff;
}
.copy_icon{
  margin-right: 16px;
}
.parcel_head{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.parcel_head_value{
  font-size: 16px;
}
.parcel_count{
  width: 7%;
  margin-right: 20px;
}
.parcel_full_info{
  width: 80%;
  padding: 20px 0;
}
.parcel_copy{
  width: 13%;
  margin-left: 30px;
  height: fit-content;
}
.parcel_input{
  margin-top: 10px;
  margin-bottom: 32px;
}

.parcel_inputs{
  display: flex;
  align-items: end;
}
.parcel_item{
  margin-right: 20px;
}
.parcel_size{
  margin: 0;
}
.input_sm{
  width: 80px;
}
.form_head{
  white-space: pre;
}

.currency{
  background: #FFFFFF;
  border-radius: 3px;
  border: none;
  margin-right: 20px;
  width: 80px;
  position: relative;
  bottom: 48px;
}
.parcel_add{
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px auto 0;
}
.parcel_invoice{
  width: 95%;
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 56px auto 0;
  padding: 20px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px
}
.add_parcel{
  padding: 8px 23px;
  display: flex;
  align-items: center;
  background: $mainBg;
  border: none;
  box-shadow: none;
}
.parcel_detal{
  display: flex;
  align-items: center;
  p{
    margin-right: 60px;
  }
}
.add_icon{
  margin-right: 12px;
}
.position--relative {position: relative}

.invoice_head{
  font-size: $rb-18;
  line-height: $rb-24;
  font-family: $r_medium;
  margin-bottom: 6px;
}
.parcel_total{
  p{
    font-size: 15px;
    line-height: 24px;
    /* identical to box height, or 160% */


    /* Dark/1 */

    color: #00000060;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
.parcel_price{
  p{
    margin-right: 24px;
  }
}

.spending_type{
  max-width: 150px;
}
.usd{
  margin-right: 12px;
}
.total_price_detail{
  border: 1px solid #8C8C8C;
  box-sizing: border-box;
  border-radius: 4px;
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  p{
    margin-right: 32px;
    font-size: $rb-18;
    font-family: $r_medium;
    margin-bottom: 0;
  }
}
.parcel_additional_info{
  width: 95%;
  margin: 60px auto 0;
  p{
    font-size: 16px;
    margin-bottom: 16px;
  }
}
.parcel_info_third_step{
  padding-left: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
}

.parcel_inputs_third{
  align-items: start;
}

.info_wrapper{
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .customer_data {
    display: block;
  }
  .sender_data, .receiver_data {
    margin-bottom: 30px;
  }
  .sender_tabs {
    width: 100%;
  }
  .sender_tabs .option:last-child {
    margin: 0;
  }
  .receipt_first_step {
    grid-template-columns: 1fr;
  }
  .receipt_stepper {
    width: 100%;
  }
  .sender_form, .receiver_form {
    padding: 15px;
    margin-bottom: 60px;
  }
  .form_input {
    // width: 96%;
  }
  .sender_form {
    border-right: none;
    border-bottom: 1px solid #8C8C8C;
  }
  .form_sm {
    width: 96%;
  }
  .receipt {
    margin-top: 60px;
    padding: 0;
  }
  .parcel_count{
    display: none;
  }
  .parcel_head {
    display: flex;
    align-items: start;
    justify-content: space-between;
    flex-direction: column-reverse;
    a{
      margin-bottom: 10px;
    }
  }
  .parcel_info, .parcel_invoice, .parcel_add{
    width: 100%;
  }
  .parcel_full_info {
    width: 100%;
  }
  .parcel_full_info{
    position: relative;
    padding: 20px 16px;
  }
  .parcel_copy{
    position: absolute;
    right: 0;
    width: unset;
  }
  .input_sm{
    width: 80px;
  }
  .parcel_inputs{
    flex-wrap: wrap;
  }
  .parcel_add{
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .parcel_detal{
    flex-direction: column;
    align-items: flex-start;
    margin-left: 16px;
    margin-right: 16px;
    p{
      margin-bottom: 16px;
    }
  }
  .add_parcel{
    width: 100%;
    justify-content: center;
    margin-bottom: 16px;
  }
  .parcel_invoice{
    grid-template-columns: 1fr;
  }
.invoice_total{
  grid-row: 1;
}
  .invoice_details{
    grid-row: 2;
  }
  .info_wrapper{
    width: 100%;
    margin: 0 auto;
  }
}



////////////////SPRINT 6///////////
.pay_with_card{
  width: 92%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px;
  background: #fff;

}
.card_head{
  display: flex;
  justify-content: end;
}
.card_content{
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: 56px;
}

.total_head{
  font-size: $rb-18;
  font-family: $r_medium;
  margin-bottom: 24px;
}

.total_item{
  width: 100%;
  border-bottom: 1px solid #8C8C8C;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.total_item_head, .total_item_currency, .total_item_amount{
  font-size: $rb-16;
  line-height: $rb-16;
  margin: 0;
}
.total_item_head{
  width: 60%;
}
.total_last{
  margin-top: 50px;
}
.privacy_head{
  font-size: $rb-24;
  margin-bottom: 16px;
}
.privacy_part{
  width: 70%;
  margin: 0 auto;
  margin-top: 40px;
}

.privacy_item{
  margin-bottom: 12px;
}
@media (max-width: 768px) {
  .card_content {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
    gap: 24px;
  }
  .privacy_part{
    width: 100%;
  }
}

.success_name{
  font-size: 24px;
  margin: 0;
}

.building_icon{
  width: 200px;
}
.suces_icon{
  width: 20px;
  margin-right: 16px;
}
.mb-16px{
  margin-bottom: 16px;
}

.form_submit{
  position: relative;
  img{
    margin-right: 8px;
  }
}

.print{
  background: #fff;
    color: #000;

  margin-left: 16px;
}
.submit_pos{
  position: relative;
}
.success_contact_items{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0;
  margin-top: 32px;
}

.success_item{
  height: 55px;
  align-items: center;
  border-right: 1px solid #8C8C8C;
  justify-content: center;
}
.success_item:last-child{
  border: none
}
@media (max-width: 768px) {
  .success_check{
    display: none;
  }
  .success_avia{
    padding: 40px 16px;
  }
  .success_contact_items{
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    margin-top: 32px;
  }
  .success_item{
    height: 55px;
    align-items: center;
    border-right: none;
    justify-content: start;
  }
  .success_desc{
    margin-bottom: 24px;
  }
  .form_submit_parent{
    margin-top: 24px;
  }
}


.tracking{
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
}

.tracking_desc{
  margin-top: 60px;
  margin-bottom: 12px;
}

.tracking_input{
  margin: 0 0 80px;
}

.tracking_info{
  margin-bottom: 20px;
}

.avia_card{
  display: flex;
  align-items: center;
  padding: 26px;
  border: 1px solid #8C8C8C;
  margin-bottom: 16px;
  img{
    margin-right: 48px;
  }

}
.avia_id{
  font-size: $rb-24;
  margin-bottom: 8px;
  font-family: $r_medium;
}
.direction{
  p{
    font-size: $rb-16;
  }
  img{
    margin: 0 8px;
  }
}
.more{
  background: none;
  border: 0.5px solid #D40511;
  border-radius: 4px;
  box-shadow: none;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 30px;
  font-size: $rb-16;
  color: #D40511;
}


.plane_icon{
  margin-right: 32px;
}

.avia_tracking_map{
  margin-top: 32px;
}

.tracking_map_progress{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 7px;
  margin-top: 30px;
}
.progress_completed{
  height: 6px;
  background: $green;
  border-radius: 4px;
}

.progress_not_completed{
  height: 6px;
  border-radius: 4px;
  background: #D2D2D2;
}

.tracking_where{
  p{
    font-size: $rb-16;
    font-family: $r_bold;;
    margin-right: 16px;
  }
  p:last-child{
    margin-left: 16px;
  }
display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 60px;
}


.tracking_div{
  box-shadow: 0px 2px 26px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  margin-top: 60px;
  padding: 30px;
  .track_head{
    font-size: $rb-24;
    font-family: $r_medium;
    margin-bottom: 56px;
  }
}
.tracking_icon{
  width: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.tracking_item_data{
  width: 88%;
  border-bottom: 1px solid #8C8C8C;
  padding-bottom: 30px;
}

.connecter{
  width: 2px;
  height: 150px;
  background: #FFCC00;
}


.mob_menu {
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: none;
  position: fixed;
  top: 0px;
  // z-index: 9999999999;
  // position: fixed;
  // top: 0px;
  // left: -100vw;
  // transition: .3s ease-in-out;
}
.dispatch_day{
  font-size: $rb-16;
  font-family: $r_light;
  margin-bottom: 2px;
}

.dispatch_date{
  font-size: $rb-16;
  font-family: $r_bold;
  margin-bottom: 20px;
}

.status_data{
  margin-bottom: 20px;
  p{
   font-size: $rb-16;
    margin-right: 16px;
  }
}

.status_delivered{
  color: $green;
}

.status_name{
  margin-left: 16px;
}

.tracking_map{
  font-size: $rb-16;
}

@media (max-width: 768px) {

  .tracking {
    width: 100%;
  }
  .tracking_input{
    margin-bottom: 40px;
  }
  .avia_card{
    flex-direction: column;
    align-items: start;
  }
  .more{
    width: 100%;
    margin-top: 16px;
  }
  .tracking_icon{
    width: 13%;

  }
  .tracking_item_data{
    width: 87%;
  }
  .tracking_div{
    padding: 16px;
  }
  .connecter{
    height: 120px;
  }
}

// cavi additions
.active-pagination{
  color: $red;
}
.line-break {
  white-space: pre-wrap;
}

.kiv_photo{
  background-size: cover!important;
}

.menu_item_name{
  img{
    transition: .3s ease-in-out;
  }
}
.menu_item_non_selected{
  .menu_opened_items{
    display: none;
  }
  .menu_item_name{
    img{
      transform: rotate(180deg);
    }
  }
}

.img-modal{
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;
  padding: 32px 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  .layer{
    background-color: rgba(0, 0, 0, .54);
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;
    z-index: 1;
  }
  .close-modal{
    width: max-content;
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 2;

    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  img{
    width: 80vw;
    height: 80vh;
    object-fit: cover;
    z-index: 2;
  }
}

.corporative{
  &.bk-img{
    background-size: cover;
  }
}


.brief_info_item_icon{
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.brief_info_item_name{
  font-family: $r_bold;
  font-size: $rb-18;
  line-height: $rb-24;
  text-align: center;
  margin-bottom: 7px;
}

.brief_info_item_desc{
  font-size: $rb-15;
  line-height: $rb-24;
  text-align: center;
}

.opportunities_brief_wrapper{
  p{
    font-size: $rb-15;
    line-height: $rb-24;
    margin-bottom: 32px;
  }
  h1, h2, h3{
    font-family: $r_bold;
    font-size: $rb-18;
    line-height: $rb-24;
    margin-bottom: 16px;
  }
}

.content_desc ul li {
  margin-bottom: 16px;
  font-family: $r_medium;
  list-style-type: none;
  position: relative;
}

.content_desc ul {
  padding-left: 27px;
  margin-bottom: 40px;
}
.content_desc ul li:before {
  content: '';
  position: absolute;
  left: -26px;
  top: 12px;
  font-size: 1.1em;
  width: 12px;
  height: 2px;
  background: #D40511;
}

.content_desc p{
  margin-bottom: 24px;
}

.w-100{
  width: 100%;
}

.hide{
  display: none!important;
}

iframe{
  width: 100%;
  border: none;
}

.courier_text-area{
  min-height: 116px;
}

.receipt_courier_step{
  max-width: 590px;
  margin: 0 auto;
}